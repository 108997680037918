import { __exports as decorators } from '../../../../../_virtual/decorators.js';
export { __exports as default } from '../../../../../_virtual/decorators.js';
import './decorators/inline.js';
import { i as inline } from '../../../../../_virtual/inline.js';
decorators.__esModule = true;
decorators.registerDefaultDecorators = registerDefaultDecorators; // istanbul ignore next

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    'default': obj
  };
}
var _decoratorsInline = inline.exports;
var _decoratorsInline2 = _interopRequireDefault(_decoratorsInline);
function registerDefaultDecorators(instance) {
  _decoratorsInline2['default'](instance);
}
