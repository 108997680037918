var AF = "Afghanistan";
var AX = "Åland Islands";
var AL = "Albania";
var DZ = "Algeria";
var AS = "American Samoa";
var AD = "Andorra";
var AO = "Angola";
var AI = "Anguilla";
var AQ = "Antarctica";
var AG = "Antigua and Barbuda";
var AR = "Argentina";
var AM = "Armenia";
var AW = "Aruba";
var AU = "Australia";
var AT = "Austria";
var AZ = "Azerbaijan";
var BS = "Bahamas";
var BH = "Bahrain";
var BD = "Bangladesh";
var BB = "Barbados";
var BY = "Belarus";
var BE = "Belgium";
var BZ = "Belize";
var BJ = "Benin";
var BM = "Bermuda";
var BT = "Bhutan";
var BO = "Bolivia, Plurinational State of";
var BA = "Bosnia and Herzegovina";
var BW = "Botswana";
var BV = "Bouvet Island";
var BR = "Brazil";
var IO = "British Indian Ocean Territory";
var VG = "Virgin Islands, British";
var BN = "Brunei Darussalam";
var BG = "Bulgaria";
var BF = "Burkina Faso";
var BI = "Burundi";
var KH = "Cambodia";
var CM = "Cameroon";
var CA = "Canada";
var CV = "Cape Verde";
var KY = "Cayman Islands";
var CF = "Central African Republic";
var TD = "Chad";
var CL = "Chile";
var CN = "China";
var CX = "Christmas Island";
var CO = "Colombia";
var KM = "Comoros";
var CG = "Congo";
var CK = "Cook Islands";
var CR = "Costa Rica";
var CI = "Côte d'Ivoire";
var HR = "Croatia";
var CU = "Cuba";
var CY = "Cyprus";
var CZ = "Czech Republic";
var CD = "Congo, the Democratic Republic of the";
var DK = "Denmark";
var DJ = "Djibouti";
var DM = "Dominica";
var DO = "Dominican Republic";
var TL = "Timor-Leste";
var EC = "Ecuador";
var EG = "Egypt";
var SV = "El Salvador";
var GQ = "Equatorial Guinea";
var ER = "Eritrea";
var EE = "Estonia";
var ET = "Ethiopia";
var FK = "Falkland Islands (Malvinas)";
var FO = "Faroe Islands";
var FJ = "Fiji";
var FI = "Finland";
var FR = "France";
var GF = "French Guiana";
var PF = "French Polynesia";
var TF = "French Southern Territories";
var GA = "Gabon";
var GM = "Gambia";
var GE = "Georgia";
var DE = "Germany";
var GH = "Ghana";
var GI = "Gibraltar";
var GR = "Greece";
var GL = "Greenland";
var GD = "Grenada";
var GP = "Guadeloupe";
var GU = "Guam";
var GT = "Guatemala";
var GG = "Guernsey";
var GN = "Guinea";
var GW = "Guinea-Bissau";
var GY = "Guyana";
var HT = "Haiti";
var HM = "Heard Island and McDonald Islands";
var HN = "Honduras";
var HK = "Hong Kong";
var HU = "Hungary";
var IS = "Iceland";
var IN = "India";
var ID = "Indonesia";
var IR = "Iran, Islamic Republic of";
var IQ = "Iraq";
var IE = "Ireland";
var IL = "Israel";
var IT = "Italy";
var JM = "Jamaica";
var JP = "Japan";
var JE = "Jersey";
var JO = "Jordan";
var KZ = "Kazakhstan";
var KE = "Kenya";
var KI = "Kiribati";
var KR = "Korea, Republic of";
var XK = "Kosovo, Republic of";
var KW = "Kuwait";
var KG = "Kyrgyzstan";
var LA = "Lao People's Democratic Republic";
var LV = "Latvia";
var LB = "Lebanon";
var LS = "Lesotho";
var LR = "Liberia";
var LY = "Libya";
var LI = "Liechtenstein";
var LT = "Lithuania";
var LU = "Luxembourg";
var MO = "Macao";
var MK = "Macedonia, the former Yugoslav Republic of";
var MG = "Madagascar";
var MW = "Malawi";
var MY = "Malaysia";
var MV = "Maldives";
var ML = "Mali";
var MT = "Malta";
var MH = "Marshall Islands";
var MQ = "Martinique";
var MR = "Mauritania";
var MU = "Mauritius";
var YT = "Mayotte";
var MX = "Mexico";
var FM = "Micronesia, Federated States of";
var MD = "Moldova, Republic of";
var MC = "Monaco";
var MN = "Mongolia";
var ME = "Montenegro";
var MS = "Montserrat";
var MA = "Morocco";
var MZ = "Mozambique";
var MM = "Myanmar";
var NA = "Namibia";
var NR = "Nauru";
var NP = "Nepal";
var NL = "Netherlands";
var AN = "Netherlands Antilles";
var NC = "New Caledonia";
var NZ = "New Zealand";
var NI = "Nicaragua";
var NE = "Niger";
var NG = "Nigeria";
var NU = "Niue";
var NF = "Norfolk Island";
var KP = "Korea, Democratic People's Republic of";
var MP = "Northern Mariana Islands";
var NO = "Norway";
var OM = "Oman";
var PK = "Pakistan";
var PW = "Palau";
var PS = "Palestine, State of";
var PA = "Panama";
var PG = "Papua New Guinea";
var PY = "Paraguay";
var PE = "Peru";
var PH = "Philippines";
var PN = "Pitcairn";
var PL = "Poland";
var PT = "Portugal";
var PR = "Puerto Rico";
var QA = "Qatar";
var RE = "Réunion";
var RO = "Romania";
var RU = "Russian Federation";
var RW = "Rwanda";
var SH = "Saint Helena, Ascension and Tristan da Cunha";
var KN = "Saint Kitts and Nevis";
var LC = "Saint Lucia";
var PM = "Saint Pierre and Miquelon";
var VC = "Saint Vincent and the Grenadines";
var WS = "Samoa";
var SM = "San Marino";
var ST = "São Tomé and Príncipe";
var SA = "Saudi Arabia";
var SN = "Senegal";
var RS = "Serbia";
var SC = "Seychelles";
var SL = "Sierra Leone";
var SG = "Singapore";
var SK = "Slovakia";
var SI = "Slovenia";
var SB = "Solomon Islands";
var SO = "Somalia";
var ZA = "South Africa";
var GS = "South Georgia and the South Sandwich Islands";
var SS = "South Sudan";
var ES = "Spain";
var LK = "Sri Lanka";
var SD = "Sudan";
var SR = "Suriname";
var SJ = "Svalbard and Jan Mayen";
var SZ = "Swaziland";
var SE = "Sweden";
var CH = "Switzerland";
var SY = "Syrian Arab Republic";
var TW = "Taiwan";
var TJ = "Tajikistan";
var TZ = "Tanzania, United Republic of";
var TH = "Thailand";
var TG = "Togo";
var TK = "Tokelau";
var TO = "Tonga";
var TT = "Trinidad and Tobago";
var TN = "Tunisia";
var TR = "Turkey";
var TM = "Turkmenistan";
var TC = "Turks and Caicos Islands";
var TV = "Tuvalu";
var VI = "Virgin Islands, U.S.";
var UG = "Uganda";
var UA = "Ukraine";
var AE = "United Arab Emirates";
var GB = "United Kingdom";
var US = "United States";
var UM = "United States Minor Outlying Islands";
var UY = "Uruguay";
var UZ = "Uzbekistan";
var VU = "Vanuatu";
var VA = "Holy See (Vatican City State)";
var VE = "Venezuela, Bolivarian Republic of";
var VN = "Viet Nam";
var WF = "Wallis and Futuna";
var EH = "Western Sahara";
var YE = "Yemen";
var ZM = "Zambia";
var ZW = "Zimbabwe";
var country = {
  AF: AF,
  AX: AX,
  AL: AL,
  DZ: DZ,
  AS: AS,
  AD: AD,
  AO: AO,
  AI: AI,
  AQ: AQ,
  AG: AG,
  AR: AR,
  AM: AM,
  AW: AW,
  AU: AU,
  AT: AT,
  AZ: AZ,
  BS: BS,
  BH: BH,
  BD: BD,
  BB: BB,
  BY: BY,
  BE: BE,
  BZ: BZ,
  BJ: BJ,
  BM: BM,
  BT: BT,
  BO: BO,
  BA: BA,
  BW: BW,
  BV: BV,
  BR: BR,
  IO: IO,
  VG: VG,
  BN: BN,
  BG: BG,
  BF: BF,
  BI: BI,
  KH: KH,
  CM: CM,
  CA: CA,
  CV: CV,
  KY: KY,
  CF: CF,
  TD: TD,
  CL: CL,
  CN: CN,
  CX: CX,
  CO: CO,
  KM: KM,
  CG: CG,
  CK: CK,
  CR: CR,
  CI: CI,
  HR: HR,
  CU: CU,
  CY: CY,
  CZ: CZ,
  CD: CD,
  DK: DK,
  DJ: DJ,
  DM: DM,
  DO: DO,
  TL: TL,
  EC: EC,
  EG: EG,
  SV: SV,
  GQ: GQ,
  ER: ER,
  EE: EE,
  ET: ET,
  FK: FK,
  FO: FO,
  FJ: FJ,
  FI: FI,
  FR: FR,
  GF: GF,
  PF: PF,
  TF: TF,
  GA: GA,
  GM: GM,
  GE: GE,
  DE: DE,
  GH: GH,
  GI: GI,
  GR: GR,
  GL: GL,
  GD: GD,
  GP: GP,
  GU: GU,
  GT: GT,
  GG: GG,
  GN: GN,
  GW: GW,
  GY: GY,
  HT: HT,
  HM: HM,
  HN: HN,
  HK: HK,
  HU: HU,
  IS: IS,
  IN: IN,
  ID: ID,
  IR: IR,
  IQ: IQ,
  IE: IE,
  IL: IL,
  IT: IT,
  JM: JM,
  JP: JP,
  JE: JE,
  JO: JO,
  KZ: KZ,
  KE: KE,
  KI: KI,
  KR: KR,
  XK: XK,
  KW: KW,
  KG: KG,
  LA: LA,
  LV: LV,
  LB: LB,
  LS: LS,
  LR: LR,
  LY: LY,
  LI: LI,
  LT: LT,
  LU: LU,
  MO: MO,
  MK: MK,
  MG: MG,
  MW: MW,
  MY: MY,
  MV: MV,
  ML: ML,
  MT: MT,
  MH: MH,
  MQ: MQ,
  MR: MR,
  MU: MU,
  YT: YT,
  MX: MX,
  FM: FM,
  MD: MD,
  MC: MC,
  MN: MN,
  ME: ME,
  MS: MS,
  MA: MA,
  MZ: MZ,
  MM: MM,
  NA: NA,
  NR: NR,
  NP: NP,
  NL: NL,
  AN: AN,
  NC: NC,
  NZ: NZ,
  NI: NI,
  NE: NE,
  NG: NG,
  NU: NU,
  NF: NF,
  KP: KP,
  MP: MP,
  NO: NO,
  OM: OM,
  PK: PK,
  PW: PW,
  PS: PS,
  PA: PA,
  PG: PG,
  PY: PY,
  PE: PE,
  PH: PH,
  PN: PN,
  PL: PL,
  PT: PT,
  PR: PR,
  QA: QA,
  RE: RE,
  RO: RO,
  RU: RU,
  RW: RW,
  SH: SH,
  KN: KN,
  LC: LC,
  PM: PM,
  VC: VC,
  WS: WS,
  SM: SM,
  ST: ST,
  SA: SA,
  SN: SN,
  RS: RS,
  SC: SC,
  SL: SL,
  SG: SG,
  SK: SK,
  SI: SI,
  SB: SB,
  SO: SO,
  ZA: ZA,
  GS: GS,
  SS: SS,
  ES: ES,
  LK: LK,
  SD: SD,
  SR: SR,
  SJ: SJ,
  SZ: SZ,
  SE: SE,
  CH: CH,
  SY: SY,
  TW: TW,
  TJ: TJ,
  TZ: TZ,
  TH: TH,
  TG: TG,
  TK: TK,
  TO: TO,
  TT: TT,
  TN: TN,
  TR: TR,
  TM: TM,
  TC: TC,
  TV: TV,
  VI: VI,
  UG: UG,
  UA: UA,
  AE: AE,
  GB: GB,
  US: US,
  UM: UM,
  UY: UY,
  UZ: UZ,
  VU: VU,
  VA: VA,
  VE: VE,
  VN: VN,
  WF: WF,
  EH: EH,
  YE: YE,
  ZM: ZM,
  ZW: ZW
};
export { AD, AE, AF, AG, AI, AL, AM, AN, AO, AQ, AR, AS, AT, AU, AW, AX, AZ, BA, BB, BD, BE, BF, BG, BH, BI, BJ, BM, BN, BO, BR, BS, BT, BV, BW, BY, BZ, CA, CD, CF, CG, CH, CI, CK, CL, CM, CN, CO, CR, CU, CV, CX, CY, CZ, DE, DJ, DK, DM, DO, DZ, EC, EE, EG, EH, ER, ES, ET, FI, FJ, FK, FM, FO, FR, GA, GB, GD, GE, GF, GG, GH, GI, GL, GM, GN, GP, GQ, GR, GS, GT, GU, GW, GY, HK, HM, HN, HR, HT, HU, ID, IE, IL, IN, IO, IQ, IR, IS, IT, JE, JM, JO, JP, KE, KG, KH, KI, KM, KN, KP, KR, KW, KY, KZ, LA, LB, LC, LI, LK, LR, LS, LT, LU, LV, LY, MA, MC, MD, ME, MG, MH, MK, ML, MM, MN, MO, MP, MQ, MR, MS, MT, MU, MV, MW, MX, MY, MZ, NA, NC, NE, NF, NG, NI, NL, NO, NP, NR, NU, NZ, OM, PA, PE, PF, PG, PH, PK, PL, PM, PN, PR, PS, PT, PW, PY, QA, RE, RO, RS, RU, RW, SA, SB, SC, SD, SE, SG, SH, SI, SJ, SK, SL, SM, SN, SO, SR, SS, ST, SV, SY, SZ, TC, TD, TF, TG, TH, TJ, TK, TL, TM, TN, TO, TR, TT, TV, TW, TZ, UA, UG, UM, US, UY, UZ, VA, VC, VE, VG, VI, VN, VU, WF, WS, XK, YE, YT, ZA, ZM, ZW, country as default };
