import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';

import { SearchBarModule, LoaderModule } from '@shure/cloud/shared/ui/components';
import { PrismButtonComponent } from '@shure/prism-angular-components';

import { SwitchOrganizationService } from './services/switch-organization.service';
import { SwitchOrganizationMainComponent } from './switch-organization-main.component';
import { ChangeOrganizationDialogComponent } from './ui/change-organization-dialog/change-organization-dialog.component';
import { CreateOrganizationDialogComponent } from './ui/create-organization/create-organization-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MatInputModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatDialogModule,
		TranslocoModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatAutocompleteModule,
		MatIconModule,
		MatListModule,
		SearchBarModule,
		MatTooltipModule,
		LoaderModule,
		PrismButtonComponent
	],
	exports: [SwitchOrganizationMainComponent],
	providers: [SwitchOrganizationService],
	declarations: [
		CreateOrganizationDialogComponent,
		SwitchOrganizationMainComponent,
		ChangeOrganizationDialogComponent
	]
})
export class SwitchOrganizationModule {}
