<div id="sh-create-organization-dialog-container-div" *transloco="let t">
	<ng-container *ngIf="formGroup">
		<form [formGroup]="formGroup">
			<h1 id="sh-create-organization-title-h1" mat-dialog-title>
				{{ t('cloud.shared.switch-org.create-organization') }}
			</h1>

			<mat-dialog-content id="sh-create-organization-content-div">
				<div id="sh-create-organization-title-div">
					{{ t('cloud.shared.switch-org.provide-info-about-org') }}
				</div>
				<mat-form-field id="sh-create-organization-name-field">
					<mat-label id="sh-create-organization-name-mat-label">{{
						t('cloud.shared.switch-org.organization-name')
					}}</mat-label>
					<input
						[placeholder]="t('cloud.shared.switch-org.organization-name')"
						matInput
						formControlName="organizationName"
					/>
					<mat-error
						*ngIf="
							(formGroup.controls['organizationName'].hasError('required') ||
								formGroup.controls['organizationName'].hasError('whitespace')) &&
							formGroup.controls['organizationName'].touched
						"
						>{{ t('cloud.shared.error-labels.organization-name-required') }}
					</mat-error>
					<mat-error
						*ngIf="
							formGroup.controls['organizationName'].hasError('maxlength') &&
							formGroup.controls['organizationName'].touched
						"
						>{{
							t('cloud.shared.error-labels.must-not-be-greater-than-length', {
								label: t('cloud.shure-cloud.org-settings.organization-name'),
								number: 150
							})
						}}
					</mat-error>
				</mat-form-field>
				<mat-form-field id="sh-create-organization-company-name-field">
					<mat-label id="sh-create-organization-company-name-mat-label">{{
						t('cloud.shared.switch-org.company-name')
					}}</mat-label>
					<input
						[placeholder]="t('cloud.shared.switch-org.company-name')"
						matInput
						formControlName="companyName"
						[matAutocomplete]="auto"
					/>
					<!-- Icon displayed inside the input when selected option is verified -->
					<img
						class="input-icon"
						*ngIf="selectedCompanyOption?.isVerified"
						src="./assets/icons/verified.svg"
						id="sh-create-organization-verify-icon"
					/>

					<!-- Autocomplete dropdown with icons -->
					<mat-autocomplete #auto="matAutocomplete">
						<mat-option *ngFor="let option of filteredOptions | async" [value]="option.companyName">
							{{ option.companyName }}
							<img *ngIf="option.isVerified" src="./assets/icons/verified.svg" />
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>

				<!-- The below code can be uncommented when we support the verified organizations feature -->
				<!-- <ng-container *ngIf="!selectedCompanyOption?.isVerified">
					<mat-checkbox id="sh-create-organization-isverified-mat-checkbox" formControlName="isVerified">
						<mat-label id="sh-create-organization-isverified-mat-label">
							{{ t('cloud.shared.switch-org.verify-organization-label') }}
						</mat-label>
					</mat-checkbox>
				</ng-container> -->
				<div id="sh-create-organization-action-buttons-div">
					<button mat-button color="accent" (click)="closeDialog()" id="sh-create-organization-button-cancel">
						{{ t('cloud.shared.button-text.cancel') }}
					</button>
					<button
						mat-button
						[disabled]="formGroup.invalid"
						color="accent"
						(click)="createOrganization()"
						id="sh-create-organization-create-org-button"
					>
						{{ t('cloud.shared.switch-org.create-view-organization') }}
					</button>
				</div>
			</mat-dialog-content>
		</form>
	</ng-container>
</div>
