/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, Tokens } from '@okta/okta-auth-js';
// eslint-disable-next-line @typescript-eslint/naming-convention
import OktaSignIn, { WidgetOptions } from '@okta/okta-signin-widget';
import assertNever from 'assert-never';

import { AppEnvironment, APP_ENVIRONMENT } from '@shure/cloud/shared/utils/config';

@Component({
	selector: 'sh-signin',
	templateUrl: './okta-signin-main.component.html',
	styleUrls: ['./okta-signin-main.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
	public signinWidget!: OktaSignIn;
	public oktaConfig!: WidgetOptions;

	constructor(
		@Inject(APP_ENVIRONMENT) public appEnv: AppEnvironment,
		@Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
		@Inject(TranslocoService) public translocoService: TranslocoService
	) {}

	public async ngOnInit(): Promise<void> {
		const signInMethod = this.appEnv.signInMethod ?? 'okta-embedded-widget';
		switch (signInMethod) {
			case 'okta-hosted':
				await this.doOktaHostedSignin();
				break;
			case 'okta-embedded-widget':
				await this.doOktaEmbeddedWidgetSignin();
				break;
			default:
				assertNever(signInMethod);
		}
	}

	public ngOnDestroy(): void {
		if (this.signinWidget) {
			this.signinWidget.remove();
		}
	}

	private async doOktaHostedSignin() {
		await this.oktaAuth.signInWithRedirect();
	}

	private async doOktaEmbeddedWidgetSignin() {
		this.initSignInWidget(); // re-init to make sure we have the correct env values, such as language
		// When navigating to a protected route, the route path will be saved as the `originalUri`
		// If no `originalUri` has been saved, then redirect back to /dashboard
		const originalUri = this.oktaAuth.getOriginalUri();
		if (!originalUri || originalUri === window.location.origin) {
			this.oktaAuth.setOriginalUri('/');
		}

		const tokens: Tokens = await this.signinWidget.showSignInToGetTokens({ el: '#okta-signin-container' });
		await this.oktaAuth.handleLoginRedirect(tokens);
	}

	public removeNonSecondaryIDPElements() {
		document.querySelector('#form19')?.setAttribute('style', 'display:none');
		document.getElementsByClassName('auth-footer')[0]?.setAttribute('style', 'display:none');
		document.getElementsByClassName('auth-divider')[0]?.setAttribute('style', 'display:none');
		document.getElementsByClassName('registration-container')[0]?.setAttribute('style', 'display:none');
	}

	public initSignInWidget() {
		// Reference ./node_modules/@okta/okta-signin-widget/types/src/types/options.d.ts
		// https://developer.okta.com/docs/guides/style-the-widget/style-okta-hosted/
		// https://github.com/okta/okta-signin-widget#configuration
		// The most useful info is found on github!!
		//
		// Okta will translate the entire signin widget labels based on the language
		// settings. It appears to support nearly all of the languages we need to support,
		// They default to english if the language is not supported.
		this.oktaConfig = {
			baseUrl: 'https://' + this.appEnv.authorization.oktaDomain,
			clientId: this.appEnv.authorization.clientId,
			issuer: 'https://' + this.appEnv.authorization.oktaDomain + this.appEnv.authorization.authServerPath,
			redirectUri: window.location.origin + '/signin/callback',
			scopes: this.appEnv.authorization.scopes,
			colors: {
				brand: '#b2ff33' // Shure Ignition Green
			},
			logo: '/assets/images/shure_logo.png',
			language: this.translocoService.getActiveLang(),
			authClient: <never>this.oktaAuth,
			features: {
				// At least registration and rememberMe values don't appear to make a difference
				// https://github.com/okta/okta-signin-widget/issues/2436
				// OKTA-481348
				// See https://super-widget.oktaprise.com/ for expected behavior
				registration: true, // enables the "register" or "signup" button.
				rememberMe: true, // Setting to false will remove the checkbox to save username
				showPasswordToggleOnSignInPage: true // enables eye to show password
			},
			useClassicEngine: this.appEnv.useClassicEngine,
			helpLinks: {
				// forgotPassword: '',
				// help: 'http://www.shure.com',
				custom: [{ text: 'shure.com', href: 'http://www.shure.com', target: '_blank' }]
			},
			registration: {
				parseSchema: (schema, onSuccess, _onFailure): void => {
					// handle parseSchema callback
					onSuccess(schema);
				},
				preSubmit: (postData, onSuccess, _onFailure) => {
					// handle preSubmit callback
					onSuccess(postData);
				},
				postSubmit: (response, onSuccess, _onFailure) => {
					// handle postsubmit callback
					onSuccess(response);
				}
			},
			idps: []
		};

		if (this.appEnv.authorization.enableSecondaryIDP) {
			this.oktaConfig.idps = <never>[
				{ id: this.appEnv.authorization.secondaryIDP, text: 'Shure Incorporated Associate? Log In' } // TODO: LBF 3/14/22 localize
			];
		}
		this.signinWidget = new OktaSignIn(this.oktaConfig);
		this.signinWidget.on('afterRender', () => {
			// In Admin portal we should display only shure associates login
			if (this.appEnv.showOnlyIdp) {
				this.removeNonSecondaryIDPElements();
			}
		});
	}
}
