import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, EMPTY, map, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';

import {
	GetNotificationResponse,
	NotificationsEndpointApiService
} from '@shure/cloud/shared/data-access/notifications';
import { NotificationsStore, NotificationStoreData } from '@shure/cloud/shared/notifications-store';

import { NotificationDetailsComponent } from '../notification-details/notification-details.component';

@Component({
	selector: 'sh-notifications-popup',
	templateUrl: './notifications-popup.component.html',
	styleUrls: ['./notifications-popup.component.scss']
})
export class NotificationsPopupComponent implements OnInit, OnDestroy {
	public application: string;
	public notifications$: Observable<GetNotificationResponse[] | null> = EMPTY;
	public currentDate = new Date();
	public readonly destroy$ = new Subject<void>();

	constructor(
		private notificationsStore: NotificationsStore,
		public notificationService: NotificationsEndpointApiService,
		public dialogRef: MatDialog,
		public router: Router,
		public cdr: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: { application?: string }
	) {
		this.application = <string>data?.application;
	}

	/**
	 * Initializes the component and subscribe to the notifications store.
	 */
	public ngOnInit(): void {
		// Subscribe to the notifications data
		this.notificationsStore.notifications$.pipe(takeUntil(this.destroy$)).subscribe((notifications) => {
			this.notifications$ = of(
				<GetNotificationResponse[]>(<unknown>notifications.filter((n) => !n.readAt && !n.archivedAt))
			);
			this.cdr.detectChanges();
		});
	}

	/**
	 * Closes all dialogs and navigates to the notifications details page.
	 */
	public notificationDetails(): void {
		this.dialogRef.closeAll();
		this.router.navigateByUrl('notifications-details');
	}

	/**
	 * Returns the current date in UTC format as a string.
	 */
	public getCurrentDateInUTC(): string {
		const currentDate = new Date();
		const currentdateFormat = new Date(currentDate.getTime() + 10000);
		return currentdateFormat.toISOString().slice(0, 19) + 'Z';
	}

	/**
	 * Opens a dialog with details of the selected notification.
	 * @param notification The notification to display details for
	 */
	public openPopup(notification: NotificationStoreData | GetNotificationResponse): void {
		this.dialogRef.closeAll();
		this.dialogRef.open(NotificationDetailsComponent, {
			width: '500px',
			data: { notificationId: notification.id, isListPage: false }
		});
	}

	/**
	 * Marks all unread notifications as read.
	 */
	public clearAll(): void {
		this.notifications$
			.pipe(
				takeUntil(this.destroy$),
				switchMap((notificationList) => {
					if (!notificationList || notificationList.length === 0) {
						return of(null);
					}

					const selectedIds = notificationList.map((notification) => notification.id);
					const requestData = {
						application: this.application,
						body: selectedIds.map((id) => ({
							id: id,
							isRead: true
						}))
					};

					return this.notificationService.postNotifications(requestData).pipe(
						switchMap(() => this.notifications$),
						take(1),
						map((notifications) => {
							if (notifications) {
								selectedIds.forEach((id) => {
									const notification = notifications.find((n) => n.id === id);
									if (notification) {
										notification.readAt = this.getCurrentDateInUTC();
									}
								});
							}
							return notifications;
						}),
						catchError((_error) => {
							return of(null);
						})
					);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((updatedNotifications) => {
				if (updatedNotifications !== null) {
					this.notificationsStore.prependOrPatchNotifications(
						<NotificationStoreData[]>(<unknown>updatedNotifications)
					);
				}
			});
	}

	/**
	 * Closes all open dialogs.
	 */
	public closedialog(): void {
		this.dialogRef.closeAll();
	}

	/**
	 * Marks specified notifications as read.
	 * @param notificationIds Array of notification IDs to mark as read
	 */
	public markedAsRead(notificationIds: string[]): void {
		const requestData = {
			application: this.application,
			body: notificationIds.map((item) => ({
				id: item,
				isRead: true
			}))
		};

		this.notificationService
			.postNotifications(requestData)
			.pipe(
				take(1),
				switchMap(() => this.notifications$),
				take(1),
				map((notifications) => {
					if (!notifications) {
						return null;
					}
					const updatedNotification = notifications.find((n) => notificationIds.includes(n.id));
					if (updatedNotification) {
						updatedNotification.readAt = this.getCurrentDateInUTC();
					}

					return notifications;
				}),
				catchError((_error) => {
					return of(null);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((updatedNotifications) => {
				if (updatedNotifications !== null) {
					this.notificationsStore.prependOrPatchNotifications(
						<NotificationStoreData[]>(<unknown>updatedNotifications)
					);
				}
			});
	}

	/**
	 * Closes all dialogs and navigates to the notifications list page.
	 */
	public notificationsList(): void {
		this.dialogRef.closeAll();
		this.router.navigateByUrl('/notifications-list');
	}

	/**
	 * Performs cleanup when the component is destroyed.
	 */
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
