import { Injectable } from '@angular/core';

import { NotificationsStore, NotificationStoreData } from '@shure/cloud/shared/notifications-store';

import { RTUData } from '../models/real-time-in-app-notification-events.interface';

@Injectable({
	providedIn: 'root'
})
export class RealTimeInAppBrowserNotificationService {
	public ws: WebSocket | null = null;
	public retryCount = 0;
	public retryDelay = 2000; // 2 seconds
	private maxRetries = 3;
	private connectionCheckInterval = 5000; // Check every 5 seconds
	private connectionCheckTimer?: ReturnType<typeof setTimeout>;
	private token = '';
	private application: string | undefined = '';
	private notificationsWebSocketUrl: string | undefined = '';

	constructor(private notificationsStore: NotificationsStore) {}

	// Connect to the WebSocket with given credentials
	public connectWebSocket({
		token,
		application,
		notificationsWebSocketUrl
	}: {
		token: string;
		application?: string;
		notificationsWebSocketUrl?: string;
	}): void {
		this.token = token;
		this.application = application;
		this.notificationsWebSocketUrl = notificationsWebSocketUrl;

		// Ensure application id and WebSocket URL are provided
		if (!(this.application && this.notificationsWebSocketUrl)) {
			// eslint-disable-next-line no-console
			console.log('No application id or WebSocket URL was provided, hence cannot connect to the WebSocket');
			return;
		}

		// Construct WebSocket URL with query parameters
		const url = new URL(this.notificationsWebSocketUrl);
		url.searchParams.append('application', this.application);
		url.searchParams.append('Authorization', `Bearer ${this.token}`);

		this.disconnectWebSocket(); // Close existing connection if any
		this.ws = new WebSocket(url.toString());
		this.attachWebSocketHandlers(); // Setup event handlers
	}

	// Disconnect from the WebSocket
	public disconnectWebSocket(): void {
		if (this.ws) {
			this.ws.close(1000); // Close with normal closure code
			this.ws = null;
		}
		this.stopConnectionCheck(); // Stop checking the connection
	}

	// Setup WebSocket event handlers
	private attachWebSocketHandlers(): void {
		if (!this.ws) return;

		this.ws.onopen = (): void => {
			this.retryCount = 0; // Reset retry count when connected
			this.startConnectionCheck(); // Start monitoring the connection
		};

		this.ws.onclose = (e: CloseEvent): void => {
			this.stopConnectionCheck(); // Stop monitoring on close
			if (e.code !== 1000) {
				// eslint-disable-next-line no-console
				console.log('WebSocket closed. Attempting to reconnect...', e);
				this.retryConnection(); // Try to reconnect if not normal closure
			} else {
				// eslint-disable-next-line no-console
				console.log('WebSocket closed normally. No reconnection will be attempted.');
			}
		};

		this.ws.onerror = (e: Event): void => {
			// eslint-disable-next-line no-console
			console.error('WebSocket error observed:', e);
			this.retryConnection(); // Try to reconnect on error
		};

		this.ws.onmessage = (e: MessageEvent): void => {
			try {
				const eventData = JSON.parse(e.data);
				this.handleEvent(eventData); // Handle the incoming message
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Error parsing incoming Websocket message:', error);
			}
		};
	}

	// Attempt to reconnect to the WebSocket
	private retryConnection(): void {
		if (this.retryCount >= this.maxRetries) {
			// eslint-disable-next-line no-console
			console.log('Maximum retries reached for Websocket. No further attempts will be made.');
			return;
		}
		this.retryCount++;
		setTimeout(() => {
			if (this.token) {
				this.connectWebSocket({
					token: this.token,
					application: this.application,
					notificationsWebSocketUrl: this.notificationsWebSocketUrl
				});
			}
		}, this.retryDelay);
	}

	// Begin periodic checks of the WebSocket connection
	private startConnectionCheck(): void {
		this.connectionCheckTimer = setInterval(() => {
			if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
				// eslint-disable-next-line no-console
				console.log('Websocket Connection check failed. Trying to reconnect...');
				this.retryConnection();
			}
		}, this.connectionCheckInterval);
	}

	// Stop periodic checks of the WebSocket connection
	private stopConnectionCheck(): void {
		if (this.connectionCheckTimer) {
			clearInterval(this.connectionCheckTimer);
		}
	}

	// Handle and process incoming WebSocket messages
	private handleEvent(rtuData: RTUData): void {
		if (rtuData?.data) {
			this.notificationsStore.prependOrPatchNotifications(<NotificationStoreData[]>(<unknown>[rtuData.data]));
		}
	}
}
