/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createVerifiedOrganization } from '../fn/organizations/create-verified-organization';
import { CreateVerifiedOrganization$Params } from '../fn/organizations/create-verified-organization';
import { getAllMyOrganizations } from '../fn/organizations/get-all-my-organizations';
import { GetAllMyOrganizations$Params } from '../fn/organizations/get-all-my-organizations';
import { GetMyCompaniesResponse } from '../models/get-my-companies-response';
import { getMyUniqueCompanies } from '../fn/organizations/get-my-unique-companies';
import { GetMyUniqueCompanies$Params } from '../fn/organizations/get-my-unique-companies';
import { OrganizationDetails } from '../models/organization-details';
import { OrganizationList } from '../models/organization-list';

@Injectable({ providedIn: 'root' })
export class OrganizationsApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createVerifiedOrganization()` */
  static readonly CreateVerifiedOrganizationPath = '/api/v1';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVerifiedOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVerifiedOrganization$Response(params: CreateVerifiedOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'statusCode'?: number;
'body'?: {
'message'?: string;
'messageKey'?: string;
'data'?: OrganizationDetails;
};
}>> {
    return createVerifiedOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createVerifiedOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVerifiedOrganization(params: CreateVerifiedOrganization$Params, context?: HttpContext): Observable<{
'statusCode'?: number;
'body'?: {
'message'?: string;
'messageKey'?: string;
'data'?: OrganizationDetails;
};
}> {
    return this.createVerifiedOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'statusCode'?: number;
'body'?: {
'message'?: string;
'messageKey'?: string;
'data'?: OrganizationDetails;
};
}>): {
'statusCode'?: number;
'body'?: {
'message'?: string;
'messageKey'?: string;
'data'?: OrganizationDetails;
};
} => r.body)
    );
  }

  /** Path part for operation `getMyUniqueCompanies()` */
  static readonly GetMyUniqueCompaniesPath = '/api/v1/companies/mine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMyUniqueCompanies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyUniqueCompanies$Response(params?: GetMyUniqueCompanies$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMyCompaniesResponse>> {
    return getMyUniqueCompanies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMyUniqueCompanies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyUniqueCompanies(params?: GetMyUniqueCompanies$Params, context?: HttpContext): Observable<GetMyCompaniesResponse> {
    return this.getMyUniqueCompanies$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMyCompaniesResponse>): GetMyCompaniesResponse => r.body)
    );
  }

  /** Path part for operation `getAllMyOrganizations()` */
  static readonly GetAllMyOrganizationsPath = '/api/v1/mine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMyOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMyOrganizations$Response(params?: GetAllMyOrganizations$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationList>> {
    return getAllMyOrganizations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMyOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMyOrganizations(params?: GetAllMyOrganizations$Params, context?: HttpContext): Observable<OrganizationList> {
    return this.getAllMyOrganizations$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationList>): OrganizationList => r.body)
    );
  }

}
