import { _ as _if } from '../../../../../../_virtual/if.js';
import '../utils.js';
import '../exception.js';
import { __exports as utils } from '../../../../../../_virtual/utils.js';
import { e as exception } from '../../../../../../_virtual/exception.js';
(function (module, exports) {
  exports.__esModule = true; // istanbul ignore next

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      'default': obj
    };
  }
  var _utils = utils;
  var _exception = exception.exports;
  var _exception2 = _interopRequireDefault(_exception);
  exports['default'] = function (instance) {
    instance.registerHelper('if', function (conditional, options) {
      if (arguments.length != 2) {
        throw new _exception2['default']('#if requires exactly one argument');
      }
      if (_utils.isFunction(conditional)) {
        conditional = conditional.call(this);
      } // Default behavior is to render the positive path if the value is truthy and not empty.
      // The `includeZero` option may be set to treat the condtional as purely not empty based on the
      // behavior of isEmpty. Effectively this determines if 0 is handled by the positive path or negative.

      if (!options.hash.includeZero && !conditional || _utils.isEmpty(conditional)) {
        return options.inverse(this);
      } else {
        return options.fn(this);
      }
    });
    instance.registerHelper('unless', function (conditional, options) {
      if (arguments.length != 2) {
        throw new _exception2['default']('#unless requires exactly one argument');
      }
      return instance.helpers['if'].call(this, conditional, {
        fn: options.inverse,
        inverse: options.fn,
        hash: options.hash
      });
    });
  };
  module.exports = exports['default'];
})(_if, _if.exports);
