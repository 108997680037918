import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SystemService } from '@shure/cloud/device-management/devices/data-access';

import { environment } from '../../environments/environment';

const localStorageKeyPrefix = 'shure.sysapi';
type LocalStorageKey = 'url' | 'apiKey';

@Injectable()
export class CloudSystemService implements SystemService {
	public getSysApiConnectInfo(): Observable<{ url: string; apiKey: string }> {
		if (this.isConfigOverrideInLocalStorage()) {
			return of({
				url: this.getValueFromLocalStorage('url'),
				apiKey: this.getValueFromLocalStorage('apiKey')
			});
		}

		if ('sysApi' in environment) {
			return of({
				url: environment.sysApi?.url ?? '',
				apiKey: environment.sysApi?.apiKey ?? ''
			});
		}

		return of({ url: 'missing_from_env', apiKey: 'missing_from_env' });
	}

	public isSystemReady(): Observable<boolean> {
		return of(true);
	}

	private isConfigOverrideInLocalStorage(): boolean {
		return this.getValueFromLocalStorage('url') !== '';
	}

	private getValueFromLocalStorage(key: LocalStorageKey): string {
		const prefixedKey = `${localStorageKeyPrefix}.${key}`;
		return window.localStorage.getItem(prefixedKey) ?? '';
	}
}
