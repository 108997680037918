<div *transloco="let t">
	<div class="sh-switch-org-container" [ngClass]="contentOrientation()">
		<div id="sh-switch-org-default-org-char-div" [style.background-color]="colorIndex()">
			{{ initials() }}
		</div>
		<div id="sh-switch-org-default-org-name-div" [matTooltip]="organizationName()" matTooltipPosition="above">
			{{ organizationName() }}
		</div>
		<button
			*ngIf="appEnv.featureFlags?.changeOrganization !== false"
			mat-button
			id="sh-switch-org-change-button"
			(click)="openChangeOrganization()"
		>
			{{ t('cloud.shared.switch-org.change') }}
		</button>
	</div>
	<div id="sh-switch-org-line-div"></div>
</div>
