import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';
import {
	DeviceBehaviorPlugin,
	DeviceImage,
	DeviceImageBehaviorPlugin
} from '@shure/shared/angular/device-behavior/core';

/**
 * `Mxa902DeviceBehaviorPluginBase` provides an base for common feature description across applications,
 * fx. designer, cui etc.
 *
 * **Do not put app specifics into this class. If app specific setup is required create a
 * plugin for the app.**
 */
export class Mxa902DeviceBehaviorPlugin implements DeviceBehaviorPlugin, DeviceImageBehaviorPlugin {
	public readonly models: DeviceModel[] = [DeviceModel.MXA902S];

	public getImage(model: DeviceModel): DeviceImage | undefined {
		switch (model) {
			case DeviceModel.MXA902S:
				return {
					lightsBackground: 'device-mxa902-s.svg',
					live: 'device-mxa902-s.svg',
					virtual: 'device-mxa902-s.svg',
					liveBack: 'device-mxa902-s.svg',
					virtualBack: 'device-mxa902-s.svg'
				};
			default:
				return undefined;
		}
	}
}
