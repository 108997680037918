<ng-container *transloco="let t">
	<mat-card-header id="notification-card-header">
		<mat-card-title id="notification-card-title" class="header-style">{{
			t('cloud.shared.notification.title')
		}}</mat-card-title>
	</mat-card-header>
	<span id="current-date" class="current-date-style">{{ currentDate | date: 'MMMM d, y' }}</span>
	<p id="loading-message" class="loading-style" *ngIf="(notifications$ | async) === null">
		{{ t('cloud.shared.notification.loading') }}
	</p>
	<div id="notification-list-container" class="notification-list-container">
		<mat-list id="notification-list">
			<mat-list-item
				id="notification-list-item"
				class="list-style"
				*ngFor="let notification of notifications$ | async"
			>
				<div mat-line id="notification-message" class="message-style">
					<div id="custom-message" class="custom-message">
						{{ notification.message }}
					</div>
				</div>
				<div mat-line id="notification-date" class="custom-date">
					{{ notification.sentAt | date: 'hh:mm a  MMM-d-yyyy' }}
				</div>
				<div id="notification-button" class="button-style">
					<span id="clear-button" class="clear-buttons" (click)="markedAsRead([notification.id])">{{
						t('cloud.shared.notification.clear')
					}}</span>
					<span id="details-button" (click)="openPopup(notification)" class="details-button">{{
						t('cloud.shared.notification.details')
					}}</span>
				</div>
			</mat-list-item>
		</mat-list>
	</div>
	<p id="no-notification-message" class="no-notification" *ngIf="(notifications$ | async)?.length === 0">
		{{ t('cloud.shared.notification.no-notification') }}
	</p>
	<div class="button-row button-group">
		<span id="view-all-button" class="close-button" (click)="notificationsList()">{{
			t('cloud.shared.notification.view-all')
		}}</span>
		<span id="close-button" class="close-button" (click)="closedialog()">{{
			t('cloud.shared.notification.close')
		}}</span>
		<span
			id="clear-all-button"
			*ngIf="(notifications$ | async)?.length"
			(click)="clearAll()"
			class="clear-button-style"
			>{{ t('cloud.shared.notification.clear-all') }}</span
		>
		<button id="clear-all-button-empty" class="clear-button" *ngIf="(notifications$ | async)?.length === 0">
			{{ t('cloud.shared.notification.clear-all') }}
		</button>
	</div>
</ng-container>
