import { HTTP_INTERCEPTORS, HttpClientModule, HttpRequest } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarConfig, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoService } from '@jsverse/transloco';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { OktaAuth, OktaAuthOptions, StorageType } from '@okta/okta-auth-js';
import {
	NgHttpCachingModule,
	NgHttpCachingConfig,
	NgHttpCachingStrategy,
	NgHttpCachingLocalStorage
} from 'ng-http-caching';
import { NgxWebstorageModule } from 'ngx-webstorage';

import { DevicesDataAccessModule, SystemService } from '@shure/cloud/device-management/devices/data-access';
import { ApolloSysApiCloudModule } from '@shure/cloud/shared/apollo';
import { CloudDeviceBehaviorPluginsModule } from '@shure/cloud/shared/device-behavior';
import { FeatureRequestApiModule } from '@shure/cloud/shared/feature-request/data-access';
import { AppEnvironmentGuard } from '@shure/cloud/shared/guards/app-environment-guard';
import { NotificationsBellIconModule } from '@shure/cloud/shared/notifications-bell-icon';
import { OktaInterfaceService, OktaModule } from '@shure/cloud/shared/okta/data-access';
import { HeaderToolbarModule } from '@shure/cloud/shared/ui/components';
import { UsersApiModule } from '@shure/cloud/shared/users/data-access';
import { AnalyticsService } from '@shure/cloud/shared/utils/analytics';
import { APP_ENVIRONMENT, getShureCloudUri } from '@shure/cloud/shared/utils/config';
import { ApiBaseurlInterceptor, ApiUrlConfig, AuthTokenInterceptor, HttpModule } from '@shure/cloud/shared/utils/http';
import { InactivityService } from '@shure/cloud/shared/utils/inactivity';
import { TranslocoRootModule, translocoInitializer } from '@shure/shared/angular/utils/i18n';
import { ConsoleLoggerSink, LoggingModule, StaticLoggingConfigProvider } from '@shure/shared/angular/utils/logging';

import { environment } from '../environments/environment';
import { MaterialIconsModule } from '../modules/material-icons.module';
import { CloudSystemService } from '../services/system';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidenavComponent } from './sidenav/sidenav.component';

const appBaseURL = window.location.origin;
const shureCloudUriMap = environment.shureCloudUriMap;

const oktaDomain =
	appBaseURL.includes('services') || appBaseURL.includes('localhost')
		? environment.authorization.oktaDomain
		: environment.authorization.oktaDomainExt;

const syncStorage = environment.cdmFeatureFlags?.showOrgSwitcher ? false : true;
const storageTypes: StorageType[] = environment.cdmFeatureFlags?.showOrgSwitcher
	? ['sessionStorage']
	: ['localStorage'];

const oktaConfig: OktaAuthOptions = {
	issuer: 'https://' + oktaDomain + environment.authorization.authServerPath,
	clientId: environment.authorization.clientId,
	redirectUri: `${appBaseURL}/signin/callback`,
	scopes: environment.authorization.scopes,
	logoutUrl: getShureCloudUri(appBaseURL, shureCloudUriMap),
	postLogoutRedirectUri: getShureCloudUri(appBaseURL, shureCloudUriMap),
	pkce: true,
	tokenManager: {
		autoRenew: true,
		syncStorage: syncStorage
	},
	storageManager: {
		token: {
			storageTypes: storageTypes
		},
		cache: {
			storageTypes: storageTypes
		},
		transaction: {
			storageTypes: storageTypes
		}
	}
};
const oktaAuth = new OktaAuth(oktaConfig);

const apiUrlConfig: ApiUrlConfig = {
	apiBaseUrl: environment.orgs?.orgsApiUrl,
	rolesApiUrl: environment.orgs?.rolesApiUrl
};

const cacheApiUrls = ['/permissions', '/version', '/contactdetails', '/organizationIds'];
const ngHttpCachingConfig: NgHttpCachingConfig = {
	lifetime: 1000 * 360, // cache expire after 6 mins
	allowedMethod: ['GET'],
	cacheStrategy: NgHttpCachingStrategy.ALLOW_ALL,
	store: new NgHttpCachingLocalStorage(),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	isCacheable: (req: HttpRequest<any>): boolean | undefined => {
		//Caching the response of the cacheApiUrls
		for (const url of cacheApiUrls) {
			if (req.urlWithParams.indexOf(url) !== -1) {
				return true;
			}
		}
		// by returning "false" for all the other APIs
		return false;
	}
};

// TODO
// - Logging Sink

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		FormsModule,
		MatSidenavModule,
		MaterialIconsModule,
		TranslocoRootModule.forRoot(false),
		ReactiveFormsModule,
		HttpClientModule,
		OktaModule.forRoot(oktaAuth),
		NgIdleKeepaliveModule.forRoot(),
		NgxWebstorageModule.forRoot(),
		ApolloSysApiCloudModule.forRoot(),
		DevicesDataAccessModule,
		AppRoutingModule,
		LoggingModule.forRoot(
			environment.production ? [ConsoleLoggerSink] : [ConsoleLoggerSink],
			{ base: { default: environment.logLevel } },
			StaticLoggingConfigProvider
		),
		CloudDeviceBehaviorPluginsModule,
		HeaderToolbarModule,
		NgHttpCachingModule.forRoot(ngHttpCachingConfig),
		SidenavComponent,
		FeatureRequestApiModule.forRoot({ rootUrl: environment.orgs?.featureRequestApiUrl }),
		HttpModule.forRoot(apiUrlConfig, [
			{
				provide: HTTP_INTERCEPTORS,
				useClass: ApiBaseurlInterceptor,
				multi: true
			},
			{
				provide: HTTP_INTERCEPTORS,
				useClass: AuthTokenInterceptor,
				multi: true
			}
		]),
		UsersApiModule.forRoot({
			rootUrl: environment.orgs?.usersApiUrl
		}),
		NotificationsBellIconModule
	],
	providers: [
		{ provide: OktaInterfaceService },
		{ provide: APP_INITIALIZER, useFactory: translocoInitializer, deps: [TranslocoService], multi: true },
		{ provide: APP_ENVIRONMENT, useValue: environment },
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: <MatFormFieldDefaultOptions>{
				appearance: 'fill',
				subscriptSizing: 'dynamic',
				color: 'accent'
			}
		},
		{
			provide: MAT_RIPPLE_GLOBAL_OPTIONS,
			useValue: <RippleGlobalOptions>{
				disabled: true
			}
		},
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: <MatSnackBarConfig>{
				duration: 8000,
				verticalPosition: 'bottom',
				horizontalPosition: 'center',
				panelClass: ['mat-toolbar', 'mat-primary']
			}
		},
		InactivityService,
		AppEnvironmentGuard,
		{
			provide: SystemService,
			useClass: CloudSystemService
		},
		AnalyticsService
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
