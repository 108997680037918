import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';
// Disabling to enforce type:utils restrictions for device-behavior-core library
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DeviceImageProvider } from '@shure/shared/angular/ui/components/device-image';

import { DeviceBehaviorPluginsService } from '../../device-behavior.service';

import { DeviceImage, DeviceImageBehaviorPlugin } from './models';

export interface DeviceImagePluginOptions {
	/**
	 * Base path to assets.
	 *
	 * **Defaults to './assets/images'**
	 */
	assetsPath: string;

	/**
	 * Image to use as fallback if plugin for device model is not found.
	 *
	 * **Defaults to 'unknown-device.svg'**
	 */
	fallbackImage: string;
}

/**
 * Injection token that can be used to specify device image plugin options.
 */
export const DEVICE_IMAGE_PLUGIN_OPTIONS = new InjectionToken<DeviceImagePluginOptions>(
	'__@shure/device-plugins/device-image-options__'
);

/**
 * Provides access to images for all registed devices and can be used by features
 * that has to show a image of a given device.
 */
@Injectable({ providedIn: 'root' })
export class DeviceImageBehaviorPluginService implements DeviceImageProvider {
	private readonly options: DeviceImagePluginOptions;

	constructor(
		private readonly plugins: DeviceBehaviorPluginsService,
		@Inject(DEVICE_IMAGE_PLUGIN_OPTIONS) @Optional() options?: DeviceImagePluginOptions
	) {
		this.options = { assetsPath: './assets/images', fallbackImage: 'unknown-device.svg', ...options };
	}

	/**
	 * Get images associated with device.
	 */
	public getImage(model: DeviceModel, channelCount?: number): DeviceImage {
		const image = this.plugins.get<DeviceImageBehaviorPlugin>(model, ['getImage'])?.getImage(model, channelCount);
		return image ? this.addPathsToImage(image) : this.getFallbackImage();
	}

	/**
	 * Add paths to device image.
	 */
	private addPathsToImage(image: DeviceImage): DeviceImage {
		return {
			lightsBackground: this.addPathToLightsBackgroundImages(image.lightsBackground),
			live: this.addPathToDeviceImages(image.live),
			virtual: this.addPathToDeviceImages(image.virtual),
			liveBack: this.addPathToDeviceImages(image.liveBack),
			virtualBack: this.addPathToDeviceImages(image.virtualBack)
		};
	}

	/**
	 * Get fallback image as `DeviceImage`.
	 */
	private getFallbackImage(): DeviceImage {
		return {
			lightsBackground: this.addPathToLightsBackgroundImages(this.options.fallbackImage),
			live: this.addPathToDeviceImages(this.options.fallbackImage),
			virtual: this.addPathToDeviceImages(this.options.fallbackImage),
			liveBack: '',
			virtualBack: ''
		};
	}

	private addPathToLightsBackgroundImages(image: string): string {
		return `${this.options.assetsPath}/devices-lights/devices/${image}`;
	}

	private addPathToDeviceImages(image: string): string {
		return `${this.options.assetsPath}/devices/${image}`;
	}
}
