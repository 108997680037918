/* eslint-disable indent */

import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';
import {
	DeviceBehaviorPlugin,
	DeviceImage,
	DeviceImageBehaviorPlugin
} from '@shure/shared/angular/device-behavior/core';

/**
 * `Mxa901DeviceBehaviorPluginBase` provides an base for common feature description across applications,
 * fx. designer, cui etc.
 *
 * **Do not put app specifics into this class. If app specific setup is required create a
 * plugin for the app.**
 */
export class Mxa901DeviceBehaviorPlugin implements DeviceBehaviorPlugin, DeviceImageBehaviorPlugin {
	public readonly models: DeviceModel[] = [DeviceModel.MXA901R, DeviceModel.MXA901S];

	public getImage(model: DeviceModel): DeviceImage | undefined {
		switch (model) {
			case DeviceModel.MXA901R:
				return {
					lightsBackground: 'device-mxa901-r.svg',
					live: 'device-mxa901-r.svg',
					virtual: 'device-mxa901-r.svg',
					liveBack: 'device-mxa901-r.svg',
					virtualBack: 'device-mxa901-r.svg'
				};
			case DeviceModel.MXA901S:
				return {
					lightsBackground: 'device-mxa901-s.svg',
					live: 'device-mxa901-s.svg',
					virtual: 'device-mxa901-s.svg',
					liveBack: 'device-mxa901-s.svg',
					virtualBack: 'device-mxa901-s.svg'
				};
			default:
				return undefined;
		}
	}
}
