import { Injectable, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { Observable } from 'rxjs';

import { OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';
import {
	CreateOrganizationsDto,
	GetMyCompaniesResponse,
	OrganizationsApiService,
	StrictHttpResponse,
	OrganizationList
} from '@shure/cloud/shared/switch-organization/data-access';
import { CloseTextOption, SnackbarService } from '@shure/cloud/shared/ui/components';

import { ChangeOrganizationDialogComponent } from '../ui/change-organization-dialog/change-organization-dialog.component';
import { CreateOrganizationDialogComponent } from '../ui/create-organization/create-organization-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class SwitchOrganizationService {
	public readonly defaultDialogConfig: MatDialogConfig = {
		width: '447px',
		panelClass: 'sh-custom-dialog-container',
		restoreFocus: false
	};
	constructor(
		private dialog: MatDialog,
		private apiOrganizationsService: OrganizationsApiService,
		private snackBarService: SnackbarService,
		private translocoService: TranslocoService,
		private readonly oktaIntfService: OktaInterfaceService,
		private readonly destroyRef: DestroyRef
	) {}

	/**
	 * Opens the 'Create Organization' dialog.
	 * This method opens a modal dialog for creating an organization using `MatDialog`.
	 */
	public openCreateDialog(): MatDialogRef<CreateOrganizationDialogComponent, MatDialogConfig> {
		return this.dialog.open(CreateOrganizationDialogComponent, this.defaultDialogConfig);
	}

	/**
	 * Opens the 'Change Organization' dialog.
	 * This method opens a modal dialog for changing an organization using `MatDialog`.
	 */
	public openChangeDialog(): MatDialogRef<ChangeOrganizationDialogComponent, MatDialogConfig> {
		return this.dialog.open(ChangeOrganizationDialogComponent, this.defaultDialogConfig);
	}

	/**
	 * Sends a request to create a new organization and switch to the new organization.
	 *
	 * This method takes organization data (`createOrgData`) and makes an API call to create a verified organization.
	 * If the organization is created successfully, a message is displayed via a Snackbar. If the creation fails,
	 * an error message is displayed.
	 *
	 * @param {CreateOrganizationsDto} createOrgData - The data needed to create the organization.
	 */
	public createOrganization(createOrgData: CreateOrganizationsDto): void {
		this.apiOrganizationsService.createVerifiedOrganization$Response({ body: createOrgData }).subscribe({
			next: (createResponse) => {
				const orgId = createResponse.body?.body?.data?.id;
				if (orgId) {
					this.changeOrganization(orgId);
				}
				this.snackBarService.open(
					this.translocoService.translate('cloud.shared.switch-org.organization-created'),
					CloseTextOption.Ok
				);
			},
			error: (error) => {
				const errorMessage = error.error;
				this.snackBarService.open(
					this.translocoService.translate(
						errorMessage?.i18nKey
							? 'cloud.shared.error-labels.' + errorMessage.i18nKey
							: errorMessage.message
					),
					CloseTextOption.Ok
				);
			}
		});
	}

	/**
	 * Changes the current organization session to the specified organization ID.
	 *
	 * This function sets the Okta session tenant to the given organization ID and reloads the window.
	 * The session is set through a service call to `setOktaSessionTenant$`.
	 *
	 * @param {string} organizationId - The ID of the organization to switch to.
	 */
	public changeOrganization(organizationId: string): void {
		this.oktaIntfService
			.setOktaSessionTenant$(organizationId, true)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: () => {
					window.location.reload();
				}
			});
	}

	/**
	 * Retrieves the unique list of companies associated with the current user.
	 * This method makes an API call to get a list of companies that the current user is associated with.
	 */
	public getMyCompanies(): Observable<StrictHttpResponse<GetMyCompaniesResponse>> {
		return this.apiOrganizationsService.getMyUniqueCompanies$Response();
	}

	/**
	 * Retrieves the unique list of companies associated with the current user.
	 * This method makes an API call to get a list of companies that the current user is associated with.
	 */
	public getAllMyOrganizations(): Observable<StrictHttpResponse<OrganizationList>> {
		return this.apiOrganizationsService.getAllMyOrganizations$Response();
	}
}
