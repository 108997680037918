import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SigninGuard: CanActivateFn = (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
	const router = inject(Router);
	return signinCheck(router);
};
async function signinCheck(router: Router): Promise<boolean | UrlTree> {
	const isAuthenticated = await inject(OKTA_AUTH).isAuthenticated();
	if (isAuthenticated) {
		return router.parseUrl('/account');
	}
	return true;
}
